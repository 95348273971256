<template>
  <div id="FlightDelayReport" class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <div class="box-S4">
        <div class="N-Page T-size-36">Fuel Report</div>
      </div>
    </div>
    <div id="filltertReport" class="wrap-Main">
      
      <div class="box-S4">
        <div class="B-carddetail flex-between-center">
          <div class="box-S2 noPadding">
            <v-select
              class="hideMessage"
              :items="listyear"
              v-model="picker"
              label="year"
              return-object
              @change="Listsummarydata()"
            ></v-select>
          </div>
          <div class="box-S2 noPadding">
            <v-select
              class="hideMessage"
              :items="itemsListCustomer"
              v-model="selectowner"
              item-text="o_name"
              item-value="o_id"
              label="Customer"
              return-object
              @change="SelectCustomer(selectowner)"
            ></v-select>
          </div>
        </div>
      </div>
    </div>
    <div  class="wrap-Main">
        <div class="box-S2">
                <div class="B-carddetail">
                        <highcharts :options="chartOptionSumfuelBymonth"></highcharts>
                </div>
        </div>
        <div class="box-S2">
                <div class="B-carddetail">
                        <highcharts :options="chartOptionSumfuelByyear"></highcharts>
                </div>
        </div>
    </div>
    <div id="tableData" class="wrap-Main">
      <div class="box-S4">
        <div class="N-Page T-size-24">Fuel Monthly Report</div>
      </div>
      <div class="box-S4">
        <v-data-table :headers="headers" :items="data">
          <!-- <template v-slot:item.ac_seat_layout_url="{ item }">
            <img class="B-img-airport" :src="item.ac_seat_layout_url" />
          </template> -->

          <template v-slot:item.total_fuel="{ item }" class="text-xs-right">
            {{
              item.tank1 +
              item.tank2 +
              item.tank3 +
              item.tank4 +
              item.tank5 +
              item.tank6 +
              item.tank7 +
              item.tank8 +
              item.tank9 +
              item.tank10
            }}
          </template>
          <template v-slot:item.this_month="{ item }" class="text-xs-right">
            <div class="b-action">
              <v-btn
                class="theme-btn-even"
                @click="createpdf(item,item.this_month, item.monthname)"
              >
                <span>VIEW</span>
                <span class="I-arrrowR"></span>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>


<script>
import feathersClientUOA from "../plugins/feathers-client-uoa";
import {
  startOfDay,
  endOfDay,
  startOfMonth,
  endOfMonth,
  lastDayOfYear,
  format,
  getDate,
} from "date-fns";
import moment from "moment";
import { Chart } from "highcharts-vue";
import { color } from "highcharts";
let header = [];
let pdftable = [];
let pdffuel = [];
let data = [];
export default {
  data: () => ({
    headers: [],
    items: [],
    total: 0,
    loading: false,
    dialog: false,
    formModel: {},
    type: "month",
    nameeee: "Totalfuel PTTEP Tank1",
    picker: {},
    dateSelect: new Date().toISOString().substr(0, 7),
    menu: false,
    // itemsListCompany: [],
    //Edit
    itemsListCustomer: [],
    // selectcompany:"",
    selectowner: "",
    ArrListDelayReport: [],
    DataDelayReport: [],

    //Add
    userdata: null,
    data,
    header,
    pdftable,
    totaltime: "",
    pdffuel,
    totalfuel: 0,
    totalfuelarray: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    chartOptionSumfuelBymonth:{},
    DatachartSumfuelBymonth:[],
    chartOptionSumfuelByyear:{},
    DatachartSumfuelByyear:[],
  }),
  computed: {},
  async mounted() {
    //Add
    this.userdata = this.$CurrentUser.GetCurrentUser();
    this.picker = this.dateSelect;
    // this.SelectListCompany();
    const currentYear = new Date().getFullYear();
    const range = (start, stop, step) =>
      Array.from(
        { length: (stop - start) / step + 1 },
        (_, i) => start + i * step
      );
    this.listyear = range(currentYear, currentYear - 10, -1);
    this.picker = this.listyear[0];
    await this.SelectListOwner();
    await this.Listsummarydata();
  },

  methods: {
    async SelectListOwner() {
      try {
        const q = {};
        //Add
        if (this.userdata.r_id == 2) {
          q.o_id = this.userdata.o_id;
        }
        var res = await feathersClientUOA
          .service("customer")
          .find({ query: q });
        this.itemsListCustomer = res.data;
        this.selectowner = {
          o_id: res.data[0].o_id,
          o_name: res.data[0].o_name,
        };
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },
    async Listsummarydata() {
      this.data = [];
      this.headers = [];
      this.DatachartSumfuelBymonth = [];
      this.DatachartSumfuelByyear = [];
      try {
        const q = {};
        q.year = this.picker;
        if (this.userdata.r_id == "2") q.customerid = this.userdata.o_id;
        else q.customerid = this.selectowner.o_id;
        var res = await feathersClientUOA
          .service("summaryfuel")
          .find({ query: q });
        console.log("res");
        console.log(res);
        this.data = res;
        this.totalfuelarray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        for (var i = 0; i < res.length; i = i + 1) {
          this.totalfuelarray[0] = this.totalfuelarray[0] + res[i].tank1;
          this.totalfuelarray[1] = this.totalfuelarray[1] + res[i].tank2;
          this.totalfuelarray[2] = this.totalfuelarray[2] + res[i].tank3;
          this.totalfuelarray[3] = this.totalfuelarray[3] + res[i].tank4;
          this.totalfuelarray[4] = this.totalfuelarray[4] + res[i].tank5;
          this.totalfuelarray[5] = this.totalfuelarray[5] + res[i].tank6;
          this.totalfuelarray[6] = this.totalfuelarray[6] + res[i].tank7;
          this.totalfuelarray[7] = this.totalfuelarray[7] + res[i].tank8;
          this.totalfuelarray[8] = this.totalfuelarray[8] + res[i].tank9;
          this.totalfuelarray[9] = this.totalfuelarray[9] + res[i].tank10;
          var totalfuelinmonth = res[i].tank1 +  res[i].tank2 +  res[i].tank3 +  res[i].tank4 +  res[i].tank5 +  res[i].tank6 +  res[i].tank7 +  res[i].tank8 +  res[i].tank9 +  res[i].tank10
          this.DatachartSumfuelBymonth.push( {name:res[i].monthname,y:totalfuelinmonth,drilldown: null})
        }

        this.headers.push({
          value: "monthname",
          text: "Month",
          sortable: true,
          class: "vdatatext"
        });
        this.headers.push({
          value: "total_flight",
          text: "Total Flight",
          sortable: true,
          class: "vdatatext"
        });
        this.headers.push({
          value: "total_fuel",
          text: "Total Fuel",
          sortable: true,
          align: "left",
          class: "vdatatext"
        });

        if (this.totalfuelarray[0] > 0) {
          this.headers.push({
            value: "tank1",
            text: "VTSH TANK",
            sortable: true,
          class: "vdatatext"
          });
          this.DatachartSumfuelByyear.push( {name:"VTSH TANK",y:this.totalfuelarray[0]})
        }
        if (this.totalfuelarray[1] > 0) {
          this.headers.push({
            value: "tank2",
            text: "PTTEP TANK 1",
            sortable: true,
          class: "vdatatext"
          });
          this.DatachartSumfuelByyear.push( {name:"PTTEP TANK 1",y:this.totalfuelarray[1]})
        }
        if (this.totalfuelarray[2] > 0) {
          this.headers.push({
            value: "tank3",
            text: "PTTEP TANK 2",
            sortable: true,
          class: "vdatatext"
          });
          this.DatachartSumfuelByyear.push( {name:"PTTEP TANK 2",y:this.totalfuelarray[2]})
        }
        if (this.totalfuelarray[3] > 0) {
          this.headers.push({
            value: "tank4",
            text: "RECOVERY",
            sortable: true,
          class: "vdatatext"
          });
          this.DatachartSumfuelByyear.push( {name:"RECOVERY",y:this.totalfuelarray[3]})
        }
        if (this.totalfuelarray[4] > 0) {
          this.headers.push({ value: "tank5", text: "BQP", sortable: true ,
          class: "vdatatext"});
          this.DatachartSumfuelByyear.push( {name:"BQP",y:this.totalfuelarray[4]})
        }
        if (this.totalfuelarray[5] > 0) {
          this.headers.push({ value: "tank6", text: "AQP", sortable: true ,
          class: "vdatatext"});
          this.DatachartSumfuelByyear.push( {name:"AQP",y:this.totalfuelarray[5]})
        }
        if (this.totalfuelarray[6] > 0) {
          this.headers.push({ value: "tank7", text: "QPS", sortable: true ,
          class: "vdatatext"});
          this.DatachartSumfuelByyear.push( {name:"QPS",y:this.totalfuelarray[6]})
        }
        if (this.totalfuelarray[7] > 0) {
          this.headers.push({ value: "tank8", text: "MDLQ", sortable: true ,
          class: "vdatatext"});
          this.DatachartSumfuelByyear.push( {name:"MDLQ",y:this.totalfuelarray[7]})
        }
        if (this.totalfuelarray[8] > 0) {
          this.headers.push({ value: "tank9", text: "DD4", sortable: true ,
          class: "vdatatext"});
          this.DatachartSumfuelByyear.push( {name:"DD4",y:this.totalfuelarray[8]})
        }
        if (this.totalfuelarray[9] > 0) {
          this.headers.push({ value: "tank10", text: "CKP", sortable: true,
          class: "vdatatext" });
          this.DatachartSumfuelByyear.push( {name:"CKP",y:this.totalfuelarray[9]})
        }
        this.headers.push({ value: "this_month", text: "", sortable: false });
      } catch (error) {
        console.log(error);
      }
      this.chartOptionSumfuelBymonth = {
        chart: {
          type: "column",
          backgroundColor: "#333232",
          borderRadius: "5px"
        },
        colors: [
          "#D91F20",
          "#3187EA",
          "#ED561B",
          "#DDDF00",
          "#24CBE5",
          "#64E572",
          "#FF9655",
          "#FFF263",
          "#6AF9C4"
        ],
        title: {
          text:" Fuel Used (Lts.) ",
          style: {
            color: "#ffffff"
          }
        },
        xAxis: {
          type: "category",
          labels: {
            format: "{value:%Y-%m-%d}",
            rotation: 45,
            align: "left",
            style: {
              color: "#ffffff"
            }
            
            
          }
        },
        yAxis: {
          title: {
            text: 'Lts',
            style:{
            color: "#ffffff"
            }
        },
        labels:{
          style:{
            color: "#ffffff"
            }
        }
        },
        legend: {
          enabled:false ,
          itemStyle: {
            color: "#ffffff"
          },
          align: "right",
          x: -30,
          verticalAlign: "top",
          y: 25,
          floating: true,
          backgroundColor: "#333232",
          borderColor: "#CCC",
          borderWidth: 1,
          shadow: false
        },
        tooltip: {
          headerFormat: "<b>{point.x}</b><br/>",
          
        },
        plotOptions: {
          column: {
            stacking: "normal",
            dataLabels: {
              enabled: true
            }
          },
          series: {
            borderColor: "#333232"
          }
        },
        series: [
        {
            name: "name",
            colorByPoint: true,
            data: this.DatachartSumfuelBymonth
        }
    ]
      };
      this.chartOptionSumfuelByyear={
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: "pie",
          backgroundColor: "#333232",
          borderRadius: "5px"
        },
        colors: [
          "#D91F20",
          "#3187EA",
          "#ED561B",
          "#DDDF00",
          "#24CBE5",
          "#64E572",
          "#FF9655",
          "#FFF263",
          "#6AF9C4"
        ],
        title: {
          text: "Fuel Used By Tank",
          style: {
            color: "#ffffff"
          }
        },
        tooltip: {
          pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>"
        },
        legend: {
          itemStyle: {
            color: "#ffffff"
          }
        },
        accessibility: {
          point: {
            valueSuffix: "%"
          }
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: true,
                format: '<b>{point.name}</b> : {point.y} Lts.',
                 style: {
            color: "#ffffff"
          }
            },
            showInLegend: true
          }
        },
        series: [
          {
            name: "Total Flight",
            colorByPoint: true,
            data: this.DatachartSumfuelByyear,
            borderColor: "#333232"
          }
        ] 
      };
    },
    pad(d) {
      return d < 10 ? "0" + d.toString() : d.toString();
    },
    DaysInMonth: (month, year) => {
      return new Date(year, month, 0).getDate();
    },
    formatvalue(n) {
      if (n > 0) {
        return (Math.round(n * 100) / 100).toLocaleString();
      } 
      else {
        return "-";
      }
    },
    async createpdf(item,month, monthname) {
      //new
      var liststation=[]
      console.log("test")
      console.log(item)
      if(item.tank1>0)
      {
             liststation.push(1); 
      }
      if(item.tank2>0)
      {
             liststation.push(2); 
      }
      if(item.tank3>0)
      {
             liststation.push(3); 
      }
      if(item.tank4>0)
      {
             liststation.push(4); 
      }
      if(item.tank5>0)
      {
             liststation.push(5); 
      }
      if(item.tank6>0)
      {
             liststation.push(6); 
      }
      if(item.tank7>0)
      {
             liststation.push(7); 
      }
      if(item.tank8>0)
      {
             liststation.push(8); 
      }
      if(item.tank9>0)
      {
             liststation.push(9); 
      }
      if(item.tank10>0)
      {
             liststation.push(10); 
      }
      this.$FuelReport.getPdf(month, this.picker, this.selectowner.o_id,monthname,liststation);
      for(var i=0;i<parseInt(liststation.length/3);i=i+1)
      {
        
      
      }
      for(var i=0;i<liststation.length%3;i=i+1)
      {

      
      }
      //old
      /*
      this.header = [];
      this.pdftable = [];
      this.pdffuel = [];
      var fixlogo = 0;
      const q = {};
      q.month = month;
      q.year = this.picker;
      q.customerid = this.selectowner.o_id;
      try {
        var res = await feathersClientUOA
          .service("fuelreport")
          .find({ query: q });
        console.log("asda");
        console.log(res);

        var uoaimage = await this.$LogoService.GetLogoBaseurl64(1);
        var customerlogo = await this.$LogoService.GetLogoBaseurl64(
          this.selectowner.o_id
        );
        if (this.selectowner.o_id == 4) {
          fixlogo = 20;
        }
      } 
      catch (error) {
        console.log(error);
      }
      var datamain = [];
      var datarow = [];
      var totaltank1 = 0;
      var totaltank2 = 0;
      var totalrecovery = 0;
      var totalfuel = 0;
      var dayinmonth = this.DaysInMonth(month, this.picker);
      var monthMMM = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      var selectedMonthName = monthMMM[month - 1];
      var i = 0;
      var day = 1;
      while (true) {
        datarow = [];
        var date =
          day +
          " " +
          selectedMonthName +
          " " +
          this.picker.toString().substring(4, 2);
        var Aircraft = "N/A";
        var tank1 = "-";
        var tank2 = "-";
        var recovery = "-";
        var totalfuelrow = 0;
        var remark = "No Flight Operate";
        if (i < res.length) {
          if (day < res[i].day1) {
            Aircraft = "N/A";
            tank1 = "-";
            tank2 = "-";
            recovery = "-";
            totalfuelrow = 0;
            remark = "No Flight Operate";
            day = day + 1;
          } else {
            Aircraft = res[i].ac_name;
            tank1 = res[i].pttep_tank_1;
            tank2 = res[i].pttep_tank_2;
            recovery = res[i].recovery_fuel;
            totalfuelrow =
              res[i].pttep_tank_1 + res[i].pttep_tank_2 + res[i].recovery_fuel;
            remark = "-";
            totaltank1 = totaltank1 + res[i].pttep_tank_1;
            totaltank2 = totaltank2 + res[i].pttep_tank_2;
            totalrecovery = totalrecovery + res[i].recovery_fuel;
            totalfuel = totalfuel + totalfuelrow;
            i = i + 1;
            if (i < res.length) {
              if (day < res[i].day1) {
                day = day + 1;
              }
            } else {
              day = day + 1;
            }
          }
        } else {
          date =
            day +
            " " +
            selectedMonthName +
            " " +
            this.picker.toString().substring(4, 2);
          Aircraft = "N/A";
          tank1 = "-";
          tank2 = "-";
          recovery = "-";
          totalfuelrow = 0;
          remark = "No Flight Operate";
          day = day + 1;
        }
        datarow = [
          {
            text: date,
            style: "tabledata",
            alignment: "center",
            border: [true, false, true, true],
          },
          {
            text: Aircraft,
            style: "tabledata",
            alignment: "center",
            bold: "True",
            border: [true, false, true, true],
          },
          {
            text: this.formatvalue(tank1),
            style: "tabledata",
            alignment: "center",
            border: [true, false, true, true],
          },
          {
            text: this.formatvalue(tank2),
            style: "tabledata",
            alignment: "center",
            border: [true, false, true, true],
          },
          {
            text: this.formatvalue(recovery),
            style: "tabledata",
            alignment: "center",
            border: [true, false, true, true],
          },
          {
            text: this.formatvalue(totalfuelrow),
            style: "tabledata",
            alignment: "center",
            border: [true, false, true, true],
          },
          {
            text: remark,
            style: "tabledata",
            alignment: "center",
            border: [true, false, true, true],
          },
        ];
        datamain.push(datarow);
        if (day >= dayinmonth + 1) {
          break;
        }
      }
      */
      
    },
    
    async SelectCustomer(args) {
      this.selectowner = args;
      await this.Listsummarydata();
    },
    async Selectmonth(picker) {
      this.picker = picker;
      await this.Listsummarydata();
    },
  },
};
</script> 
<style scoped>

</style>

<style>
.vdatatext{
  color: white !important; 
}
</style>